import { isEnabledLDFeature, useFlags } from 'src/libs/launchDarkly';

export enum Feature {
  AI_AUTOFILL_SUGGESTIONS = 'aiAutofillSuggestions',
  APPROVAL_RULES_PAGE = 'approvalRulesPage',
  BANNER_AUTO_PAYMENT = 'bannerAutoPaymentHome',
  BATCH_UPLOAD = 'batchUpload',
  BB_PAYMENT_CONNECTOR = 'bbPaymentConnector',
  BILL_PAGE_DATE_FILTER = 'billPageDateFilter',
  COMPANY_OVERVIEW = 'companyOverview',
  COMPANY_OVERVIEW_MORE_DETAILS = 'companyOverviewMoreDetails',
  EXAMPLE = 'example',
  FINBOT = 'finbot',
  INITIAL_BALANCE_MODULE = 'initialBalanceModuleApproval',
  ITAU_PAYMENT_CONNECTOR = 'itauPaymentConnector',
  INTER_PAYMENT_CONNECTOR = 'interPaymentConnector',
  SICOOB_PAYMENT_CONNECTOR = 'sicoobPaymentConnector',
  MIGRATE_SCHEDULE_BILL_DRAWER_TO_MODAL = 'migrateScheduleBillDrawerToModal',
  NEW_BILL_FIELDS = 'newBillFields',
  NEW_NOTA_FISCAL_SETUP = 'newNotaFiscalSetup',
  NEW_PURCHASE_ORDER_FORM_FIELDS = 'newPurchaseOrderFormFields',
  SANTANDER_PAYMENT_CONNECTOR = 'santanderPaymentConnector',
  SEMI_AUTOMATIC_SUGGESTIONS = 'semiAutomaticSuggestions',
  SUGGESTIONS_TAB = 'suggestionsTab',
}

type OpenFlags = Record<Feature, boolean>;

export const openFlags: Partial<OpenFlags> = {
  [Feature.EXAMPLE]: true,
  [Feature.NEW_NOTA_FISCAL_SETUP]: true,
  [Feature.NEW_PURCHASE_ORDER_FORM_FIELDS]: true,
  [Feature.SUGGESTIONS_TAB]: true,
  [Feature.APPROVAL_RULES_PAGE]: true,
  [Feature.NEW_BILL_FIELDS]: true,
};

export function useExternalFeatureFlag(feature: Feature) {
  const flags = useFlags();

  const isEnabled = isEnabledLDFeature(feature, flags);

  return { isEnabled };
}
