import classNames from 'classnames';
import { Typography } from 'src/design-system/components';
import { Divider, Stack } from '@mui/material';
import { DotIcon } from 'src/mui/_icons';

import styles from './Legends.module.scss';

type Props = {
  iconProps: {
    className: string;
    stroke?: string;
  };
  labelText: string;
};

export default function LegendItem({ iconProps, labelText }: Props) {
  return (
    <Stack direction="column" gap={0.5} alignItems="start">
      <Stack direction="row" className={styles.itemWrapper}>
        <Divider
          component="div"
          role="presentation"
          orientation="horizontal"
          textAlign="left"
          variant="fullWidth"
          classes={{ wrapper: styles.dividerWrapper, root: styles.dividerRoot }}
        >
          <Typography variant="text-xs" fontWeight={600}>
            {labelText}
          </Typography>
        </Divider>
      </Stack>
      <Stack direction="row" gap={4}>
        <Stack direction="row" gap={2} alignItems="center">
          <DotIcon
            fontSize="xxsmall"
            {...iconProps}
            className={classNames(iconProps.className)}
          />
          <Typography variant="text-xs" fontWeight={400}>
            Realizados
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
