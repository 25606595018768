import type { PortalProps } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { TradeName } from 'src/libs/finbits/Bank/types';

import AutoPaymentModal from '../AutoPaymentModal';
import VerticalStepper from '../VerticalStepper';
import {
  CertificateStep,
  FinalStep,
  GettingStartedStep,
  IntroStep,
} from '../VerticalStepper/Steps';
import type { StepperContentProps } from '../VerticalStepper/types';

type Props = PortalProps & {
  account: Account;
};

export default function BBAutoPayment({
  onClose,
  onExit,
  open = false,
  account,
}: Props) {
  const steps = [
    {
      label: 'Introdução',
      content: (props: StepperContentProps) => <IntroStep {...props} />,
    },
    {
      label: 'Envie o seu Certificado A1',
      content: (props: StepperContentProps) => <CertificateStep {...props} />,
    },

    {
      label: 'O que você precisa para começar',
      content: (props: StepperContentProps) => (
        <GettingStartedStep {...props} />
      ),
    },
    {
      label: 'Conclusão',
      content: (props: StepperContentProps) => <FinalStep {...props} />,
    },
  ];

  return (
    <AutoPaymentModal
      account={account}
      bankTradeName={TradeName.BB}
      showButtons={false}
      onClose={onClose}
      onExit={onExit}
      open={open}
    >
      <VerticalStepper
        account={account}
        onStepNext={() => {}}
        activeStepIndex={3}
        steps={steps}
        tradeName={TradeName.BB}
        onClose={onClose}
      />
    </AutoPaymentModal>
  );
}
