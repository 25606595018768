import { Box, Divider, FormControlLabel, Stack, Switch } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { Table } from 'src/mui/_scss';

import { format } from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useOverviewCashFlow } from 'src/libs/finbits/Overview/Overview';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import OverviewChart from 'src/features/overview/OverviewChart';
import RangePicker from 'src/features/overview/page-filters/RangePicker';
import { AccountsAutocomplete } from 'src/features/accounts/AccountsAutocomplete';
import { useFormatOverviewTableData } from 'src/features/overview/hooks/useFormatOverviewTableData';
import useStoredPageFilters from 'src/features/overview/hooks/useStoredPageFilters';
import OverviewPageTabs from 'src/features/overview/Tabs';
import CategoryAlert from 'src/features/overview/CategoryAlert';

import styles from './Overview.module.scss';
import Totals from './Totals';

export default function Overview() {
  const { companyId, organizationId } = useCompanyParams();

  const { isEnabled: isMoreDetailsEnabled } = useExternalFeatureFlag(
    Feature.COMPANY_OVERVIEW_MORE_DETAILS
  );

  const {
    xAxis,
    setXAxis,
    selectedAccountIds,
    setSelectedAccountIds,
    selectedDateRange,
    setSelectedDateRange,
    minDateRange,
    showMoreDetails,
    setShowMoreDetails,
  } = useStoredPageFilters();

  const { cashFlow, isLoading } = useOverviewCashFlow({
    companyId,
    organizationId,
    accountsIds: selectedAccountIds,
    startDate: format(selectedDateRange[0], 'yyyy-MM-dd'),
    endDate: format(selectedDateRange[1], 'yyyy-MM-dd'),
    groupBy: xAxis,
  });

  const { columns, rows } = useFormatOverviewTableData({
    data: cashFlow,
    groupedBy: xAxis,
  });

  return (
    <Box className={styles.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap={6}
      >
        <Typography variant="text-lg" fontWeight="bold" component="h1">
          Visão geral
        </Typography>
      </Stack>

      <OverviewPageTabs />

      <Stack direction="row" marginBlock={-2}>
        <RangePicker
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          minDate={minDateRange}
        />
        <AccountsAutocomplete
          selectedAccountIds={selectedAccountIds}
          setSelectedAccountIds={setSelectedAccountIds}
        />
        {isMoreDetailsEnabled && (
          <FormControlLabel
            className={styles.switchLabel}
            label="Mais detalhes"
            labelPlacement="start"
            checked={showMoreDetails}
            control={
              <Switch
                className={styles.switch}
                color="primary"
                inputProps={{
                  'aria-label': 'toggle switch',
                }}
                onChange={(_event, checked) => setShowMoreDetails(checked)}
              />
            }
          />
        )}
      </Stack>

      <Divider />

      <CategoryAlert />

      <Totals
        showMoreDetails={showMoreDetails}
        accountsIds={selectedAccountIds}
        startDate={selectedDateRange[0]}
        endDate={selectedDateRange[1]}
      />

      <OverviewChart
        data={cashFlow}
        xAxisGroupedBy={xAxis}
        onChangeXAxis={setXAxis}
        isLoading={isLoading}
      />

      <Stack
        data-testid="cashflow-table"
        marginTop={6}
        marginBottom={10}
        height={240}
      >
        <Table
          isLoading={isLoading}
          disableColumnSorting
          disableColumnFilter
          disableColumnSelector
          disabledMenuRightClick
          columns={columns}
          rows={rows}
          GridToolBar={null}
          pinnedColumns={{
            left: ['amount'],
          }}
        />
      </Stack>
    </Box>
  );
}
