import cn from 'classnames';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useItemTooltip, useMouseTracker } from '@mui/x-charts/ChartsTooltip';

import { toCurrency } from 'src/libs/finbits/Money';
import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';
import type { CashFlowGroupBy } from 'src/libs/finbits/Overview/enum';

import { generateVirtualElement } from './generateVirtualElement';
import styles from './CustomItemTooltip.module.scss';
import PercentageVariation from './PercentageVariation';
import { SignIndicator } from './enum';

type Props = {
  data: OverviewCashFlow[];
  groupedBy: CashFlowGroupBy;
};

export function formatCurrency(v: number) {
  return toCurrency(v, undefined, { signDisplay: 'auto' });
}

function getSignIndicator(value: number) {
  if (value === 0) return SignIndicator.ZERO;
  if (value < 0) return SignIndicator.NEGATIVE;
  return SignIndicator.POSITIVE;
}

function getAmountColor(value: number) {
  const sing = getSignIndicator(value);

  return sing === SignIndicator.NEGATIVE ? 'error.600' : undefined;
}

function buildTooltipContentValue(seriesId: unknown, item: OverviewCashFlow) {
  if (seriesId === 'debit') {
    return {
      currentAmount: item.debitAmount,
      currentAmountColor: undefined,
      variationAmount: item.debitAmountVariation,
      percentageVariation: item.debitAmountPercentageVariation,
      percentageVariationSing: getSignIndicator(
        item.debitAmountPercentageVariation
      ),
    };
  }

  if (seriesId === 'credit') {
    return {
      currentAmount: item.creditAmount,
      currentAmountColor: undefined,
      variationAmount: item.creditAmountVariation,
      percentageVariation: item.creditAmountPercentageVariation,
      percentageVariationSing: getSignIndicator(
        item.creditAmountPercentageVariation
      ),
    };
  }

  return {
    currentAmount: item.balanceAmount,
    currentAmountColor: getAmountColor(item.balanceAmount),
    variationAmount: item.balanceVariationAmount,
    percentageVariation: item.balancePercentageVariation,
    percentageVariationSing: getSignIndicator(item.balancePercentageVariation),
  };
}

function useOverviewChartTooltip(data: OverviewCashFlow[]) {
  const tooltipData = useItemTooltip();
  const mousePosition = useMouseTracker();
  const item =
    tooltipData?.identifier.dataIndex !== undefined &&
    data.at(tooltipData.identifier.dataIndex);

  if (!tooltipData || !mousePosition || !item) {
    return { visible: false, mousePosition };
  }

  // The pointer type can be used to have different behavior based on pointer type.
  const isMousePointer = mousePosition?.pointerType === 'mouse';
  // Adapt the tooltip offset to the size of the pointer.
  const yOffset = isMousePointer ? 5 : 40 - mousePosition.height;

  const isBar = tooltipData.identifier.type === 'bar';
  const seriesId = tooltipData.identifier.seriesId;

  const {
    currentAmount,
    variationAmount,
    percentageVariation,
    currentAmountColor,
    percentageVariationSing,
  } = buildTooltipContentValue(seriesId, item);

  return {
    visible: true,
    currentAmount,
    currentAmountColor,
    variationAmount,
    percentageVariation,
    percentageVariationSing,
    isBar,
    yOffset,
    isMousePointer,
    mousePosition,
  };
}

export default function CustomItemTooltip({ data, groupedBy }: Props) {
  const {
    visible,
    currentAmount,
    currentAmountColor,
    variationAmount,
    percentageVariation,
    percentageVariationSing,
    isBar,
    yOffset,
    isMousePointer,
    mousePosition,
  } = useOverviewChartTooltip(data);

  if (!visible) return null;

  return (
    <Popper
      open
      placement={isMousePointer ? 'top-end' : 'top'}
      anchorEl={generateVirtualElement(mousePosition)}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, yOffset],
          },
        },
      ]}
    >
      <Paper
        elevation={0}
        className={cn(styles.paper, {
          [styles.blackVariation]: isBar,
        })}
      >
        <Stack alignItems="start">
          <Typography
            variant="text-xs"
            fontWeight={600}
            color={currentAmountColor}
          >
            {formatCurrency(currentAmount ?? 0)}
          </Typography>

          <PercentageVariation
            groupedBy={groupedBy}
            variationAmount={variationAmount}
            percentageVariation={percentageVariation}
            percentageVariationSing={percentageVariationSing}
            variation={isBar ? 'black' : 'white'}
          />
        </Stack>
      </Paper>
    </Popper>
  );
}
