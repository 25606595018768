export enum CashFlowGroupBy {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export enum CashFlowAmountType {
  CREDIT_AMOUNT = 'creditAmount',
  DEBIT_AMOUNT = 'debitAmount',
  BALANCE_AMOUNT = 'balanceAmount',
}
