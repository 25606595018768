import { useState } from 'react';

import type { AllSeriesType, ZoomData } from '@mui/x-charts-pro';
import {
  BarPlot,
  ChartsAxisHighlight,
  ChartsClipPath,
  ChartsGrid,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainerPro,
  ZoomSetup,
} from '@mui/x-charts-pro';
import { Box, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { toFloat } from 'src/libs/finbits/Money';
import { format, parseISO } from 'src/libs/finbits/Date';
import { CashFlowGroupBy } from 'src/libs/finbits/Overview/enum';
import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';

import EmptyState from './EmptyState';
import Legends from './Legends/Legends';
import LoadingState from './LoadingState';
import ZoomTooltip from './ZoomTooltip';
import styles from './OverviewChart.module.scss';
import CustomItemTooltip from './Tooltips/CustomItemTooltip';

const CHART_PATH_ID = 'cashFlowChart';
const Y_AXIS_ID = 'yAxis';
const X_AXIS_ID = 'bar';

const dateFormatByX = {
  [CashFlowGroupBy.DAY]: 'dd/MM',
  [CashFlowGroupBy.MONTH]: 'MMM/yy',
  [CashFlowGroupBy.YEAR]: 'yyyy',
};

function formatXAxis(xAxis: CashFlowGroupBy) {
  return function formatDate(v: string) {
    return format(parseISO(v), dateFormatByX[xAxis]);
  };
}

function formatYAxis(v: number | null) {
  return toFloat(v ?? 0).toLocaleString('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });
}

function buildSeries(isEmpty: boolean): AllSeriesType[] {
  if (isEmpty) {
    return [];
  }

  return [
    {
      type: 'line',
      curve: 'linear',
      dataKey: 'balanceAmount',
      label: 'Saldo',
      color: 'black',
      showMark: true,
      id: 'balance',
    },
    {
      type: 'bar',
      dataKey: 'creditAmount',
      label: 'Entradas',
      stack: 'amountBar',
      color: '#108036',
      id: 'credit',
    },
    {
      type: 'bar',
      dataKey: 'debitAmount',
      label: 'Saídas',
      stack: 'amountBar',
      color: '#292929',
      id: 'debit',
    },
  ];
}

const fakeEmptyData = Array.from({ length: 12 }, (_item, i) =>
  format(new Date(2025, i), 'yyyy-MM-dd')
);
const fakeEmptyAmount = [10000, 1000, 0, -1000, -10000];

const defaultZoom = [
  {
    axisId: X_AXIS_ID,
    start: 0,
    end: 100,
  },
];

type Props = {
  data: OverviewCashFlow[];
  xAxisGroupedBy: CashFlowGroupBy;
  onChangeXAxis: (xAxis: CashFlowGroupBy) => void;
  isLoading?: boolean;
};

export default function OverviewChart({
  data,
  xAxisGroupedBy,
  onChangeXAxis,
  isLoading = false,
}: Props) {
  const [zoom, setZoom] = useState<ZoomData[]>(defaultZoom);

  function handleSelectXAxis(
    _event: React.MouseEvent<HTMLElement>,
    newAxis: CashFlowGroupBy
  ) {
    if (newAxis !== null) {
      onChangeXAxis(newAxis);
      setZoom(defaultZoom);
    }
  }

  const isEmpty = data.length === 0;
  const emptyOrLoading = isEmpty || isLoading;

  const axisClasses = emptyOrLoading
    ? {
        line: styles.tick,
        tick: styles.tick,
        tickLabel: styles.label,
      }
    : undefined;

  return (
    <Box className={styles.container}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={5}>
          <ToggleButtonGroup
            color="secondary"
            value={emptyOrLoading ? null : xAxisGroupedBy}
            exclusive
            onChange={handleSelectXAxis}
            aria-label="Platform"
            size="small"
            disabled={emptyOrLoading}
          >
            <ToggleButton
              className={styles.xButton}
              value={CashFlowGroupBy.YEAR}
            >
              Anos
            </ToggleButton>
            <ToggleButton
              className={styles.xButton}
              value={CashFlowGroupBy.MONTH}
            >
              Meses
            </ToggleButton>
            <ToggleButton
              className={styles.xButton}
              value={CashFlowGroupBy.DAY}
            >
              Dias
            </ToggleButton>
          </ToggleButtonGroup>
          <ZoomTooltip isEmpty={emptyOrLoading} />
        </Stack>

        <Box>{!emptyOrLoading && <Legends />}</Box>
      </Stack>

      <ResponsiveChartContainerPro
        data-testid="overview-chart"
        className={styles.chart}
        disableAxisListener={emptyOrLoading}
        margin={{ top: 40, bottom: 30, left: 50, right: 10 }}
        onZoomChange={setZoom}
        zoom={zoom}
        dataset={data}
        series={buildSeries(emptyOrLoading)}
        xAxis={
          [
            {
              id: X_AXIS_ID,
              dataKey: 'date',
              scaleType: 'band',
              valueFormatter: formatXAxis(xAxisGroupedBy),
              zoom: true,
              data: emptyOrLoading ? fakeEmptyData : undefined,
            },
          ] as any
        }
        yAxis={[
          {
            id: Y_AXIS_ID,
            scaleType: emptyOrLoading ? 'point' : undefined,
            valueFormatter: formatYAxis,
            data: emptyOrLoading ? fakeEmptyAmount : undefined,
          },
        ]}
      >
        {!emptyOrLoading && <ChartsGrid horizontal />}

        <ChartsClipPath id={CHART_PATH_ID} />
        <g clipPath={`url(#${CHART_PATH_ID})`}>
          {isLoading && <LoadingState />}
          {!isLoading && isEmpty && <EmptyState />}
          <BarPlot skipAnimation />
          <LinePlot skipAnimation />
          <MarkPlot skipAnimation />
        </g>

        <ChartsYAxis position="left" axisId={Y_AXIS_ID} classes={axisClasses} />
        <ChartsXAxis
          position="bottom"
          axisId={X_AXIS_ID}
          classes={axisClasses}
        />

        <LineHighlightPlot />
        <ChartsAxisHighlight x="band" y="line" />
        <CustomItemTooltip data={data} groupedBy={xAxisGroupedBy} />
        <ZoomSetup />
      </ResponsiveChartContainerPro>
    </Box>
  );
}
