import { useMemo } from 'react';

import type { CashFlowGroupBy } from 'src/libs/finbits/Overview/enum';
import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';

import {
  generateColumns,
  generateEmptyColumns,
  generateEmptyRows,
  generateRows,
} from 'src/features/overview/parsers/formatOverviewTableData';

type Props = {
  data: OverviewCashFlow[];
  groupedBy: CashFlowGroupBy;
};
export function useFormatOverviewTableData({ data, groupedBy }: Props) {
  const rows = useMemo(() => {
    if (!data.length) return generateEmptyRows();

    return generateRows({ data, groupedBy });
  }, [data, groupedBy]);

  const columns = useMemo(() => {
    if (!data.length) return generateEmptyColumns();

    return generateColumns({ data, groupedBy });
  }, [data, groupedBy]);

  return {
    columns,
    rows,
  };
}
