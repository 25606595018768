import { Stack } from '@mui/material';

import LegendItem from './LegendItem';
import styles from './Legends.module.scss';

export default function Legends() {
  return (
    <Stack direction="row" gap={10} className={styles.legend}>
      <LegendItem
        labelText="Saldo"
        iconProps={{
          stroke: 'black',
          className: styles.resultAmountlegendIcon,
        }}
      />

      <LegendItem
        labelText="Recebimentos"
        iconProps={{
          className: styles.creditAmountlegendIcon,
        }}
      />

      <LegendItem
        labelText="Pagamentos"
        iconProps={{
          className: styles.debitAmountlegendIcon,
        }}
      />
    </Stack>
  );
}
