import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';
import { useCurrentRoute } from 'src/router/useCurrentRoute';

import {
  BillsApprovalsType,
  useOptionalCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { useProfile } from 'src/libs/finbits/Organization/Users';
import { CompanyRole, useCurrentRole } from 'src/libs/finbits/Permissions';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

export function useInitialOrganizationRoute() {
  const { user, isLoading } = useProfile();

  const { companyId, organizationId } = useOptionalCompanyParams();

  const currentRoute = useCurrentRoute();
  const currentRole = useCurrentRole();
  const { isEnabled: isOverviewEnabled } = useExternalFeatureFlag(
    Feature.COMPANY_OVERVIEW
  );

  const hasCompany = Boolean(user?.companiesPermissions?.length);

  const company = user?.companies?.find((company) => company.id === companyId);
  const isEnabledBillsApprovals =
    company?.billsApprovals !== BillsApprovalsType.DISABLED;

  const isApprover = currentRole === CompanyRole.APPROVER;
  const isManagerOrViewer =
    currentRole === CompanyRole.MANAGER || currentRole === CompanyRole.VIEWER;

  if (isApprover && isEnabledBillsApprovals) {
    return {
      path: generateCompanyPath(RouteKey.APPROVAL, {
        companyId,
        organizationId,
      }),
      hasCompany,
      isLoading,
    };
  }

  if (isApprover && !isEnabledBillsApprovals) {
    return {
      path: generateCompanyPath(RouteKey.PROFILE, {
        companyId,
        organizationId,
      }),
      hasCompany,
      isLoading,
      forceNavigate: currentRoute?.id === RouteKey.APPROVAL,
    };
  }

  if (hasCompany && isManagerOrViewer && isOverviewEnabled) {
    return {
      path: generateCompanyPath(RouteKey.OVERVIEW, {
        companyId,
        organizationId,
      }),
      hasCompany,
      isLoading,
    };
  }

  if (hasCompany) {
    return {
      path: generateCompanyPath(RouteKey.INBOX_ITEMS, {
        companyId,
        organizationId,
      }),
      hasCompany,
      isLoading,
    };
  }

  return {
    path: `/organizations/${organizationId}/profile`,
    hasCompany,
    isLoading,
  };
}
