import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import type { RcFile } from 'antd/lib/upload';
import { FormProvider, useForm } from 'react-hook-form';
import t from 'zod';

import UploadDigitalCertificate from 'src/features/open-banking/AutoPayment/AutoPaymentModal/UploadDigitalCertificate';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

export default function CertificateStep({ onNext }: StepperContentProps) {
  const GettingStartedDecoder = t.object({
    certificate: t
      .array(t.custom<RcFile>())
      .min(1, 'Por favor, anexe o arquivo do certificado.'),
  });

  type GettingStartedType = t.TypeOf<typeof GettingStartedDecoder>;

  const form = useForm<GettingStartedType>({
    resolver: zodResolver(GettingStartedDecoder),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      certificate: [],
    },
  });

  async function handleNextStep(event: React.MouseEvent) {
    const isValid = await form.trigger();

    if (isValid) onNext?.(event);
  }

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" gap={6}>
        <UploadDigitalCertificate
          name="certificate"
          label="Arquivo .pfx do certificado:"
          acceptFiles={{ 'application/x-pkcs12': ['.pfx'] }}
        />

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleNextStep}
        >
          Começar
        </Button>
      </Box>
    </FormProvider>
  );
}
