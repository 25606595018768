import t from 'zod';

export const OverviewTotalsDecoder = t.object({
  debitAmount: t.number(),
  debitAmountNotIncluded: t.number(),
  creditAmount: t.number(),
  creditAmountNotIncluded: t.number(),
  currentBalance: t.number(),
  periodBalance: t.number(),
  resultAmount: t.number(),
});

export const OverviewCashFlowDecoder = t.object({
  balanceAmount: t.number(),
  balancePercentageVariation: t.number(),
  balanceVariationAmount: t.number(),
  balancePreviousAmount: t.number(),
  creditAmount: t.number(),
  creditAmountPercentageVariation: t.number(),
  creditAmountVariation: t.number(),
  creditPreviousAmount: t.number(),
  debitAmount: t.number(),
  debitAmountPercentageVariation: t.number(),
  debitAmountVariation: t.number(),
  debitPreviousAmount: t.number(),
  date: t.string(),
});
