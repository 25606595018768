export enum PaymentConnectionScopes {
  BOLETO = 'boleto',
  DDA = 'dda',
  PIX = 'pix',
  QRCODE = 'qrcode',
  TED = 'ted',
}

export enum BankStatementScopes {
  MANUAL_CONCILIATION = 'manual_conciliation',
  D_1 = 'd-1',
  REALTIME = 'realtime',
  AUTOMATIC_CONCILIATION = 'automatic_conciliation',
}
