import { useCallback } from 'react';

import type { TextFieldProps } from '@mui/material';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import cn from 'classnames';
import type { FileRejection } from 'react-dropzone';
import { ErrorCode, useDropzone } from 'react-dropzone';
import {
  type ControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form';
import { FileIcon, TrashIcon, UploadCloud01Icon } from 'src/mui/_icons';
import { FormField } from 'src/mui/_scss';

import { FocusIcon, snackbar } from 'src/mui';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

import { MAX_SIZE } from 'src/features/attachments/UploadAttachments/Validators';

import styles from './UploadDigitalCertificate.module.scss';

type FileExtensionsMap = {
  [mimeType: string]: string[];
};

type Props = {
  acceptFiles: FileExtensionsMap;
  label: string;
  placeholder?: string;
  TextFieldProps?: TextFieldProps;
} & Omit<ControllerProps, 'render'>;

const ERROR_MESSAGE: Record<string, string> = {
  [ErrorCode.FileInvalidType]: 'Arquivo inválido! 👉 Revise o formato',
  [ErrorCode.FileTooLarge]: 'O tamanho do arquivo ultrapassa o limite de 20MB.',
};

export default function UploadDigitalCertificate({
  acceptFiles,
  placeholder = 'ou arraste um arquivo',
  defaultValue = [],
  ...rest
}: Props) {
  const { control } = useFormContext();

  const {
    field: { value: attachments, onChange },
  } = useController({
    defaultValue,
    ...rest,
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return;

      onChange([...attachments, acceptedFiles[0]]);
    },
    [onChange, attachments]
  );

  const onDropRejected = useCallback(([rejectedFile]: FileRejection[]) => {
    const errorCode = rejectedFile.errors[0].code;
    const message = rejectedFile.errors[0].message;

    snackbar({
      variant: 'error',
      message: ERROR_MESSAGE[errorCode] || message,
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFiles,
    multiple: false,
    onDrop,
    onDropRejected,
    maxSize: MAX_SIZE,
  });

  function handleDeleteAttachment(attachment: Attachment) {
    if (!attachments) return;

    const updatedAttachments = attachments.filter(
      (a: Attachment) => a.id !== attachment.id
    );

    onChange(updatedAttachments);
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <FormField control={control} {...rest}>
        {(field) => (
          <>
            {attachments.length ? (
              <>
                {attachments.map((attachment: Attachment) => (
                  <Paper
                    className={styles.fileUploadedWrapper}
                    key={attachment.id}
                  >
                    <Box display="flex" alignItems="center" gap={4}>
                      <FocusIcon size="md" className={styles.focusIcon}>
                        <FileIcon fontSize="small" />
                      </FocusIcon>

                      <Typography
                        variant="text-sm"
                        className={styles.fileUploadedName}
                      >
                        {attachment?.name}
                      </Typography>
                    </Box>

                    <IconButton
                      onClick={() => handleDeleteAttachment(attachment)}
                      aria-label="Remover arquivo"
                      title="Remover arquivo"
                    >
                      <TrashIcon />
                    </IconButton>
                  </Paper>
                ))}
              </>
            ) : (
              <Paper
                {...field}
                {...getRootProps()}
                className={cn(styles.fileUploadedWrapper, {
                  [styles.fileUploadedError]: field.error,
                })}
              >
                <input {...getInputProps()} data-testid="file-upload" />
                <Box display="flex" alignItems="center" gap={4}>
                  <FocusIcon
                    size="md"
                    className={cn(styles.focusIcon, {
                      [styles.fileUploadedIconError]: field.error,
                    })}
                  >
                    <FileIcon fontSize="small" />
                  </FocusIcon>

                  <Typography variant="text-sm">
                    <Typography
                      component="span"
                      color="primary"
                      fontWeight="600"
                    >
                      Selecione
                    </Typography>{' '}
                    {placeholder}
                  </Typography>
                </Box>

                <FocusIcon size="md" className={styles.focusIcon}>
                  <UploadCloud01Icon fontSize="small" />
                </FocusIcon>
              </Paper>
            )}
          </>
        )}
      </FormField>
    </Box>
  );
}
