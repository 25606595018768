import { useEffect, useState } from 'react';

import { Box, Collapse } from '@mui/material';
import cn from 'classnames';
import { Typography } from 'src/design-system/components';
import { CheckCircleIcon } from 'src/mui/_icons';

import styles from './VerticalStepper.module.scss';
import type { VerticalStepperProps } from './types';

export default function VerticalStepper({
  activeStepIndex,
  onStepNext,
  steps,
  tradeName,
  onClose,
  account,
}: VerticalStepperProps) {
  const [currentStepIndex, setCurrentStepIndex] = useState(activeStepIndex);

  useEffect(() => {
    setCurrentStepIndex(activeStepIndex);
  }, [activeStepIndex]);

  function handleNext(event: React.MouseEvent) {
    event.stopPropagation();

    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex((prevIndex) => prevIndex + 1);
      onStepNext();
    }
  }

  function handleBack(event: React.MouseEvent) {
    event.stopPropagation();

    if (currentStepIndex > 0) {
      setCurrentStepIndex((prevIndex) => prevIndex - 1);
    }
  }

  function handleStepClick(stepIndex: number) {
    if (stepIndex <= activeStepIndex) {
      setCurrentStepIndex(stepIndex);
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      {steps.map((step, stepIndex) => {
        return (
          <Box
            key={stepIndex}
            className={cn(styles.step, {
              [styles.cursorNotAllowed]: stepIndex > activeStepIndex,
              [styles.grid]: stepIndex === currentStepIndex,
            })}
            onClick={() => handleStepClick(stepIndex)}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                component="h3"
                variant="text-md"
                fontWeight="600"
                className={styles.title}
                color={
                  stepIndex <= activeStepIndex ? 'common.black' : 'grey.400'
                }
              >
                <span>{stepIndex > 0 && `${stepIndex}.`}</span> {step.label}
              </Typography>

              {stepIndex < activeStepIndex &&
                stepIndex !== currentStepIndex && (
                  <CheckCircleIcon fontSize="small" className={styles.icon} />
                )}
            </Box>

            <Collapse in={stepIndex === currentStepIndex}>
              {typeof step.content === 'function'
                ? step.content({
                    onClose: onClose,
                    onNext: handleNext,
                    onPrev: handleBack,
                    account,
                    tradeName,
                  })
                : step.content}
            </Collapse>
          </Box>
        );
      })}
    </Box>
  );
}
